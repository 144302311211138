import React from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import QueryString from 'query-string';
import { navigate } from 'gatsby';
import Api from '../helpers/Api';
import Spinner from '../components/Spinner';
import {
  prepareRedirect
} from '../views/OTPView/utils';
import { flowConfigState } from '..';
import { loginBannerErrorState } from '../views/LoginView/utils';
import { ERROR } from '../constants/appCopy.json';

export default function Success() {
  const flowConfig = useRecoilValue(flowConfigState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  async function handleSeamlessLogin(authCode, targetUrl, flowId) {
    try {
        Api._setToken(authCode); // 2FA
        let payload = {
          authCode,
          flowId,
          grantType: flowConfig?.grantType
        }

        if(targetUrl) { payload = { ...payload, targetUrl}};

        const ssoResponse = await Api.getSeamlessSSO(payload);
        if(ssoResponse?.body) {
          window.location.href = ssoResponse?.body?.callbackUrl;
        } else {
          if(ssoResponse?.error?.data?.code === 'TARGET_URL_NOT_VALID') {
            window.location.href = ssoResponse?.error?.data?.callbackUrl;
            return;
          }
          setLoginBannerError(ERROR.SOMETHING_WRONG);
          navigate(`/login?flowId=${flowId}`);
        }
    } catch (e) {
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login?flowId=${flowId}`);
    }
  }

  React.useEffect(() => {
    const qs = QueryString.parse(location.search);
    const authCode = qs.authToken;
    const targetUrl = decodeURIComponent(qs.targetUrl);
    const flowId = qs.flowId;
    handleSeamlessLogin(authCode, encodeURIComponent(targetUrl), flowId);
    
  }, []);

  return <Spinner/>
}
